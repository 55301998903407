import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import SignUpSection from '../components/SignUpSection';
import OfferSection from '../components/OfferSection';
import SalaryCalculatorSection from '../components/SalaryCalculatorSection';
import BecomeRiderSection from '../components/BecomeRiderSection';
import TestimonialsSection from '../components/TestimonialsSection';
import FAQSection from '../components/FAQSection';
import ReadySection from '../components/ReadySection';
import Footer from '../components/Footer';
import useLanguage from '../hooks/useLanguage';
import useScript from '../hooks/useScript';

const LanguagePage = () => {
  const { t } = useTranslation();
  const language = useLanguage();

  if (language !== i18n.language) i18n.changeLanguage(language);

  const nlCondition = language === 'nl' || language === 'nl_en';
  useScript({ src: 'https://analytics.webgains.io/clk.min.js', condition: nlCondition });

  useEffect(() => {
    if (nlCondition) {
      window.ITCLKQ =
        window.ITCLKQ ||
        function (...args: unknown[]) {
          (window.ITCLKQ.q = window.ITCLKQ.q || []).push(args);
        };
      window.ITCLKQ.l = 1 * new Date().getTime();
      window.ITCLKQ('set', 'internal.cookie', true);
      window.ITCLKQ('set', 'internal.api', true);
      window.ITCLKQ('click');
    }
  }, [nlCondition]);

  return (
    <>
      <div>
        <Helmet>
          <title>{t('meta-title')}</title>
          <meta name="description" content={t('meta-description') || ''} />
        </Helmet>

        <Header showMenu applyButtonLink={'apply_now'} />
        <HeroSection />
        <SignUpSection />
        <OfferSection />
        <SalaryCalculatorSection />
        <BecomeRiderSection />
        <TestimonialsSection />
        <FAQSection />
        <ReadySection />
        <Footer showBottomButton={true} />
      </div>
    </>
  );
};

export default LanguagePage;
