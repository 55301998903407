import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

const NotFound = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Page not found | Flink - Join Us</title>
          <meta name="description" content="Page not found | Flink - Join Us" />
        </Helmet>
        <Header showMenu={false} applyButtonLink={'/'} />

        <div className="mx-auto -mt-12 flex min-h-screen flex-col items-center justify-center font-flink-medium">
          <h2 className="mb-5 font-flink-bold text-8xl text-flinkPink md:text-9xl">404</h2>
          <div className="mb-20 text-3xl text-gray-sonic_silver">
            Sorry, this page could not be found
          </div>
          <Link to={`/${location.search}`}>
            <a className="mb-10 rounded-2xl bg-flinkNightBlue py-3 px-6 text-3xl uppercase text-white">
              Go to home page
            </a>
          </Link>
        </div>
        <Footer showBottomButton={false} />
      </div>
    </>
  );
};

export default NotFound;
